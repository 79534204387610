.contact-page {
    background-color: #f8f9fa;
    color: #333;
    padding: 60px 0;
  }
  
  .contact-container {
    max-width: 600px;
    background: #ffffff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  }
  
  .contact-form .form-control {
    font-size: 1.1rem;
    padding: 15px;
    margin-bottom: 20px;
  }
  
  .contact-form button {
    background-color: #007bff;
    border: none;
    padding: 12px 24px;
    font-size: 1.2rem;
  }
  
  .contact-form button:hover {
    background-color: #0056b3;
  }
  